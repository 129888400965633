import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContentWrapper from './Layout/ContentWrapper';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ModalDialog from './Modal';
import Loading from './Loading';

import _ from 'lodash';

import {
    fetchImages,
    showModal
} from '../actions';

import {
    FETCH_IMAGES_QUERY,
    FETCH_IMAGES_FINISHED
} from '../actions/galleryActionTypes';

const Gallery = () => {
    const { images, statuses } = useSelector(state => ({
        images: state.gallery.images,
        statuses: state.gallery.statuses
    }));

    const dispatch = useDispatch();
    useEffect( () => {
        if (!_.has(statuses, FETCH_IMAGES_FINISHED) && !_.has(statuses, FETCH_IMAGES_QUERY)) {
            dispatch(fetchImages());
        }
    }, [dispatch, images, statuses]);
   
  
    if (_.has(statuses, FETCH_IMAGES_QUERY)) {
        return <Loading />;
    }

    return (
        <ContentWrapper>
            <Row xs={1} sm={2} md={3} className="text-break">
            {Object.entries(images).map(([key, image]) => 
                <Col key={key} className="text-center p-2">
                    <Image src={image.downloadUrl} alt={image.title} fluid rounded className="cursor-pointer"
                    onClick={() => { 
                        dispatch(showModal({
                            title: image.title,
                            body: (<div className="d-block text-center">
                                <Image src={image.downloadUrl} alt={image.title} fluid rounded/>
                                <Link className="nav-link" to={`/gallery/image/${key}`} target="_blank">
                                    Открыть в отдельной странице
                                </Link>
                                </div>),
                            size: "lg",
                            showFooter: false
                        }));
                    }}
                    />
                    <h6 className="pt-2">{image.title}</h6>
                    <small>{image.date}</small>
                    <p>{image.description}</p>
                </Col>
            )}
            </Row>
            <ModalDialog/>
        </ContentWrapper>
    );
};

export default Gallery;