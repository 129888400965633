import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ContentWrapper from './Layout/ContentWrapper';

const Loading = () => {
    return (
        <ContentWrapper>
                <h6  className="text-center">Происходит загрузка данных, пожалуйста подождите!</h6>
                <div className="text-center">
                    <Spinner animation="border" size="lg" />
                </div>
        </ContentWrapper>
    );
};

export default Loading;