import { 
    CLOSE_TOAST,
    ADD_TOAST
} from '../actions/toastsActionTypes';

import _ from 'lodash';

const INITIAL_STATE = { 
    toasts: {}
};

export const toastsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLOSE_TOAST:
            const rest = _.pickBy(state.toasts, (toast) => toast.id !== action.payload);
            return { ...state, toasts:  rest };
        case ADD_TOAST:
            return { ...state, toasts: { ...state.toasts, [action.payload.id]: action.payload } };
        default:
            return state;
    }
};