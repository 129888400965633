import React from 'react';
import _ from 'lodash';

import {
    fetchMaterials,
    MATERIALS_ROOT_PATH
} from '../../actions';
import { connect } from 'react-redux';
import { FETCH_MATERIALS_QUERY } from '../../actions/materialsActionTypes';

import MaterialsTreeBranch from './MaterialsTreeBranch';

import Loading from '../Loading';

class MaterialsTree extends React.Component {

    componentDidMount() {
        if (_.isEmpty(this.props.materials)) {
            this.loadMaterials();
        }
    }

    loadMaterials = () => {
        this.props.fetchMaterials();
    }

    render() {
        if (_.has(this.props.statuses, FETCH_MATERIALS_QUERY)) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <MaterialsTreeBranch
                    key={`branch:${MATERIALS_ROOT_PATH}`}
                    currPath={MATERIALS_ROOT_PATH}
                    materials={this.props.materials}
                />
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        materials: state.materialsTree.materials,
        statuses: state.materialsTree.statuses
    };
}

export default connect(mapStateToProps, { fetchMaterials })(MaterialsTree);


