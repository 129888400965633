import React from 'react';
import _ from 'lodash';
import MaterialsTreeNode from './MaterialsTreeNode';
import Media from 'react-bootstrap/Media';

const MaterialsTreeBranch = ({ currPath, materials }) => {
    const directChildren = {};
    const descendants = {};

    for (const [materialPath, material] of Object.entries(materials)) {
        if (material.parentPath === currPath) {
            directChildren[materialPath] = material;
        } else {
            descendants[materialPath] = material;
        }
    }

    const materialsFormatted = Object.entries(directChildren).map(([materialPath, material]) => {
        const relevantDescendants = _.pickBy(descendants, (currMaterial) => currMaterial.parentPath.startsWith(materialPath));
        const hasRelevantDescendants = !_.isEmpty(relevantDescendants);
        
        return (
            <Media key={`fragment:${materialPath}`}>
                <Media.Body>
                    <MaterialsTreeNode
                        key={`node:${materialPath}`}
                        currKey={material.key}
                        material={material}
                    >
                    {hasRelevantDescendants && 
                        <MaterialsTreeBranch
                            key={`key:${materialPath}`}
                            currPath={materialPath}
                            materials={relevantDescendants}
                        />
                    }
                    </MaterialsTreeNode>
                </Media.Body>
            </Media>
        );
    });

    return (
        <React.Fragment>
                {materialsFormatted}
        </React.Fragment>
    );
};

export default MaterialsTreeBranch;