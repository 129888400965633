import _ from 'lodash';

import { 
    FETCH_IMAGES,
    FETCH_IMAGES_QUERY,
    FETCH_IMAGE,
    FETCH_IMAGE_QUERY,
    FETCH_IMAGES_FINISHED,
    DELETE_IMAGE,
    DELETE_IMAGE_QUERY,
    ADD_IMAGE_QUERY,
    ADD_IMAGE,
    ADD_IMAGE_UPLOAD_DONE,
    ADD_IMAGE_UPLOAD_PROGRESS,
    EDIT_IMAGE,
    EDIT_IMAGE_QUERY
} from '../actions/galleryActionTypes';

const INITIAL_STATE = { 
    images: {}, 
    latestImage: null,
    statuses: {},
    uploadProgress: {}
};


export const galleryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_IMAGES_QUERY:
            return { ...state, statuses: {..._.omit(state.statuses, FETCH_IMAGES_FINISHED), FETCH_IMAGES_QUERY} };   
        case FETCH_IMAGE_QUERY:
            return { ...state, statuses: {...state.statuses, FETCH_IMAGE_QUERY} };   
        case DELETE_IMAGE_QUERY:
            return { ...state, statuses: {...state.statuses, DELETE_IMAGE_QUERY} };   
        case ADD_IMAGE_QUERY:
            return { ...state, statuses: {...state.statuses, ADD_IMAGE_QUERY} };         
        case EDIT_IMAGE_QUERY:
            return { ...state, statuses: {...state.statuses, EDIT_IMAGE_QUERY} };  
        case ADD_IMAGE_UPLOAD_DONE:
            return { ...state, uploadProgress: _.omit(state.uploadProgress, action.payload.key)}   
        case ADD_IMAGE_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: {...state.uploadProgress, [action.payload.key]: action.payload.progress } }
        case FETCH_IMAGES:
            return { ...state, images: action.payload, statuses: {..._.omit(state.statuses, FETCH_IMAGES_QUERY), FETCH_IMAGES_FINISHED } };
        case FETCH_IMAGE:
            return { ...state, images: {...state.images, ...action.payload}, statuses: _.omit(state.statuses, FETCH_IMAGE_QUERY) };
        case ADD_IMAGE:
            return { ...state, images: {...state.images, [action.payload.key]: action.payload}, statuses: _.omit(state.statuses, ADD_IMAGE_QUERY) };
        case EDIT_IMAGE:
            return { ...state, images: {...state.images, [action.payload.key]: action.payload}, statuses: _.omit(state.statuses, EDIT_IMAGE_QUERY) };
        case DELETE_IMAGE:
            const deletePicker = (_, imageKey) => { return imageKey !== action.payload.key; };
            return { ...state, images: _.pickBy(state.images, deletePicker), statuses: _.omit(state.statuses, DELETE_IMAGE_QUERY) };
        default:
            return state;
    }
};