import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';


import '../styles/style.scss';

import Header from './Layout/Header';
import UserMenu from './Layout/UserMenu';
import Footer from './Layout/Footer';

import Main from './Main';
import Autobiography from './Autobiography';
import Materials from './Materials';
import Gallery from './Gallery';
import Authorization from './Authorization';
import Error from './Error';
import Loading from './Loading';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/analytics';
import firebaseConfig from '../firebaseConfig';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';

import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore';
import reducers, { INITIAL_STATE } from '../reducers';

import Toasts from './Toasts';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab, fas);

const ManageGallery = React.lazy(() => import( './Management/ManageGallery'));
const ManageMaterials  = React.lazy(() => import( './Management/ManageMaterials'));
const ViewImage  = React.lazy(() => import( './Gallery/ViewImage'));



// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    // profileFactory: (userData, profileData, firebase) => { // how profiles are stored in database
    //     const { user } = userData
    //     return {
    //       role: 'user'
    //     }
    //   }
    //userProfile: 'users',
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
    // enableClaims: true // Get custom claims along with the profile
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
firebase.analytics();
//firebase.firestore();
 
const store = createStore(reducers,
    INITIAL_STATE,
    compose(
        applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
        reduxFirestore(firebase)
        //,window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <div className="container p-0" id="contentWrapper">
                        <BrowserRouter>
                            <Header />
                            <UserMenu />
                            <Suspense fallback={<Loading/>}>
                                <Switch>
                                    <Route path="/" exact component={Main} />
                                    <Route path="/autobiography" exact component={Autobiography} />
                                    <Route path="/materials" exact component={Materials} />
                                    <Route path="/manage/materials" exact component={ManageMaterials} />
                                    <Route path="/gallery" exact component={Gallery} />
                                    <Route path="/gallery/image/:imageId" exact component={ViewImage} />
                                    <Route path="/manage/gallery" exact component={ManageGallery} />
                                    <Route path="/authorization" exact component={Authorization} />
                                    <Route component={Error} />
                                </Switch>
                            </Suspense>
                            <Footer />
                        </BrowserRouter>
                    </div>
                    <Toasts/>
                </ReactReduxFirebaseProvider>
            </Provider>
        );
    }
}

export default App;