import React from 'react';

const Error = () => {
    return (
        <div className="row p-4 justify-content-md-center">
            <div className="col-md-auto">
                <h1>Случилась Ошибка :(</h1>
                <img src="/images/podshibniki_nig.png" alt="podshipniki" />
            </div>
        </div>
    );
}

export default Error;