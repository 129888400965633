import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
    var currentYear = new Date().getFullYear();
    return (
        <div className="pt-5 mt-3">
            <footer className="bg-light">
                <div className="row justify-content-md-center">
                    <div className="nav navbar col-md-auto">
                        <div className="nav-item">
                        Copyright © {currentYear} M. I. Kurushin   
                        </div>
                        <div className="nav-item">
                            <Link className="nav-link" to="/authorization">
                                <FontAwesomeIcon icon="lock" size="sm" transform="up-1" color="black" />
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
/** mx-auto mt-2 align-middle */
export default Footer;