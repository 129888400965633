import { 
    MODAL_ACTION_TYPE_CLOSE,
    MODAL_ACTION_TYPE_SHOW
} from './modalActionTypes';

export const closeModal = () => {
    return {
        type: MODAL_ACTION_TYPE_CLOSE
    }
};

/*
    title
    body
    commitAction
*/
export const showModal = (properties) => {
    return {
        type: MODAL_ACTION_TYPE_SHOW,
        payload: {
            ...properties
        }
    }
};