import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { materialsReducer } from './materials';
import { galleryReducer } from './gallery';
import { toastsReducer } from './toasts';
//import { firestoreReducer } from 'redux-firestore';
import { modalReducer } from './modal'

export const INITIAL_STATE = {};

export default combineReducers({
    firebase: firebaseReducer,
    //firestore: firestoreReducer,
    materialsTree: materialsReducer,
    gallery: galleryReducer,
    toasts: toastsReducer,
    modal: modalReducer
});