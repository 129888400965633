
import ProgressBar from 'react-bootstrap/ProgressBar';
import React from 'react';

class UploadProgressBar extends React.Component {
    state = { progress: 0.00 };

    UpdateProgress = (progress) => {
        this.setState({ progress: progress.toFixed(2) });
    }

    render() {
        return <ProgressBar animated now={this.state.progress} label={`${this.state.progress}%`} />
    }
};

export default UploadProgressBar;