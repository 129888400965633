export const FETCH_MATERIALS = 'FETCH_MATERIALS';
export const FETCH_MATERIALS_QUERY = 'FETCH_MATERIALS_QUERY';
export const FETCH_MATERIAL = 'FETCH_MATERIAL';
export const FETCH_MATERIAL_QUERY = 'FETCH_MATERIAL_QUERY';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const ADD_MATERIAL_QUERY = 'ADD_MATERIAL_QUERY';
export const ADD_MATERIAL_UPLOAD_PROGRESS = 'ADD_MATERIAL_UPLOAD_PROGRESS';
export const ADD_MATERIAL_UPLOAD_DONE = 'ADD_MATERIAL_UPLOAD_DONE';
export const EDIT_MATERIAL = 'EDIT_MATERIAL';
export const EDIT_MATERIAL_QUERY = 'EDIT_MATERIAL_QUERY';
export const RENAME_MATERIAL = 'RENAME_MATERIAL';
export const RENAME_MATERIAL_QUERY = 'RENAME_MATERIAL_QUERY';

export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const DELETE_MATERIAL_QUERY = 'DELETE_MATERIAL_QUERY';



