import React from 'react';
import MaterialsTree from './MaterialsTree/MaterialsTree'
import ContentWrapper from './Layout/ContentWrapper';

const Materials = () => {
    return (
        <ContentWrapper>
            <MaterialsTree />
        </ContentWrapper>
    );
}

export default Materials;