import React from 'react';
import UploadProgressBar from './UploadProgressBar';

import firebase from 'firebase/app';
import 'firebase/database';
import "firebase/storage";

import { 
    FETCH_MATERIALS,
    FETCH_MATERIAL,
    EDIT_MATERIAL,
    DELETE_MATERIAL,
    FETCH_MATERIALS_QUERY,
    FETCH_MATERIAL_QUERY,
    ADD_MATERIAL,
    ADD_MATERIAL_QUERY,
    ADD_MATERIAL_UPLOAD_PROGRESS,
    ADD_MATERIAL_UPLOAD_DONE,
    EDIT_MATERIAL_QUERY,
    DELETE_MATERIAL_QUERY,
    RENAME_MATERIAL,
    RENAME_MATERIAL_QUERY
} from './materialsActionTypes';

import { 
    showToast,
    createAndShowToast,
    createToast,
    closeToast
} from './toasts';

export const MATERIALS_ROOT_PATH = 'materials';
export const MATERIALS_FS_ROOT_PATH = 'materials';


export const MATERIAL_NODE_TYPE = {
    CONTAINER: 'CONTAINER',
    FILE: 'FILE'
};

export const createMaterial = (parentPath, nodeType, displayName, file = null) => {
    return {
        parentPath, 
        nodeType,
        displayName,
        file
    }
}

export const fetchMaterials = () => async dispatch => {
    const materialsRef = firebase.database().ref(MATERIALS_ROOT_PATH).orderByChild('displayName');
    const materials = {};

    dispatch({ type: FETCH_MATERIALS_QUERY });

    await materialsRef.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            const childVal = childSnapshot.val();
            const material = { ...childVal, key: childSnapshot.key };
            materials[`${material.parentPath}/${material.key}`] = material;
        });
    });

    dispatch({
        type: FETCH_MATERIALS,
        payload: materials
    });
    /*
    const storageRef = firebase.storage().ref();
    storageRef.child(MATERIALS_FS_ROOT_PATH).listAll().then(function(res) {
        console.log(res);
        res.prefixes.forEach(function(folderRef) {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
            console.log(`prefixes ${JSON.stringify(folderRef)}`)
        console.log(folderRef);
        });
        res.items.forEach(function(itemRef) {
            // All the items under listRef.
            console.log(`item ${JSON.stringify(itemRef)}`)
        });
        }).catch(function(err) {
        console.log(err);
        });
    */
};

export const fetchMaterial = (key)  => async dispatch => {
    dispatch({ type: FETCH_MATERIAL_QUERY });

    const fetchedMaterialRef = await firebase.database().ref(`${MATERIALS_ROOT_PATH}/${key}`).once('value');

    dispatch({
        type: FETCH_MATERIAL,
        payload: { key: fetchedMaterialRef.key, ...fetchedMaterialRef.val() }
    })
}

export const addMaterial = (material) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    // to generate the key
    const materialRef = firebase.database().ref(MATERIALS_ROOT_PATH).push();

    if (material.file) {
        const fileName = material.file.name;
        material.realFileName = fileName;
        material.realExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
       /*
            Promise Resolves with an object containing uploadTaskSnaphot which is 
            the firebase.storage.UploadTaskSnaphot returned from the storageRef.put call which happens internally.
            If databasePath is provided snapshot, key, File, and metaDataSnapshot parameters are also included.
       */
       let uploadTask;
        try {
            uploadTask = getFirebase().storage().ref().child(
                `${MATERIALS_ROOT_PATH}/${materialRef.key}/${material.displayName}.${material.realExtension}`
                ).put(
                material.file
            );
        } catch (error) {
            console.error(error);
            return;
        }

        const progressRef = React.createRef();
        const toast = createToast("Материал Загружается", <><h6 className="text-center">{material.displayName}.{material.realExtension}</h6><UploadProgressBar ref={progressRef}/></>);
        dispatch(showToast(toast));

        uploadTask.on('state_changed', function(snapshot) {
            const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            if (progressRef.current) {
                progressRef.current.UpdateProgress(progress);
            }

            dispatch({
                type: ADD_MATERIAL_UPLOAD_PROGRESS,
                payload: {
                    key: materialRef.key,
                    progress
                }
            })

            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
                default: break;
            }
        });

        await uploadTask;
        dispatch(closeToast(toast.id));

        dispatch({
            type: ADD_MATERIAL_UPLOAD_DONE,
            payload: {
                key: materialRef.key
            }
        });

        material.downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
        material.fileSize = uploadTask.snapshot.totalBytes;
    }

    dispatch({ type: ADD_MATERIAL_QUERY });

    await materialRef.set(material);
    
    dispatch({
        type: ADD_MATERIAL,
        payload: { key: materialRef.key, ...material }
    });

    dispatch(createAndShowToast("Материал Добавлен", "Материал добавлен успешно!", true, 3000));
}

export const editMaterial = (material, key) => async dispatch => {

    dispatch({ type: EDIT_MATERIAL_QUERY });

    await firebase.database().ref(`${MATERIALS_ROOT_PATH}/${key}`).set(
        material
    );

    dispatch({
        type: EDIT_MATERIAL,
        payload: {key, ...material}
    });
}

export const renameMaterial = (material, newDisplayName)  => async (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({ type: RENAME_MATERIAL_QUERY });
    
    await firebase.database().ref(`${MATERIALS_ROOT_PATH}/${material.key}`).update({
        displayName: newDisplayName
    });

    dispatch({
        type: RENAME_MATERIAL,
        payload: { ...material, displayName: newDisplayName }
    });
}

export const deleteMaterial = (material)  => async (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({ type: DELETE_MATERIAL_QUERY });

    if (material.nodeType === MATERIAL_NODE_TYPE.FILE) {
        await getFirebase().deleteFile(`${MATERIALS_ROOT_PATH}/${material.key}/${material.displayName}.${material.realExtension}`);
    }

    await firebase.database().ref(`${MATERIALS_ROOT_PATH}/${material.key}`).remove();

    dispatch({
        type: DELETE_MATERIAL,
        payload: { ...material }
    });

    dispatch(createAndShowToast("Материал Удален", "Материал удален успешно!", true, 3000));
}
