import _ from 'lodash';
import { 
    FETCH_MATERIALS,
    FETCH_MATERIAL,
    ADD_MATERIAL,
    ADD_MATERIAL_UPLOAD_PROGRESS,
    ADD_MATERIAL_UPLOAD_DONE,
    EDIT_MATERIAL,
    RENAME_MATERIAL,
    DELETE_MATERIAL,
    FETCH_MATERIALS_QUERY
} from '../actions/materialsActionTypes';

const INITIAL_STATE = { 
    materials: {}, 
    latestMaterial: null,
    statuses: {},
    uploadProgress: {}
};

export const materialsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MATERIALS_QUERY:
            return { ...state, statuses: {...state.statuses, FETCH_MATERIALS_QUERY} };          
        case FETCH_MATERIALS:
            return { ...state, materials: action.payload, statuses: _.omit(state.statuses, FETCH_MATERIALS_QUERY) };
        case FETCH_MATERIAL:
        case ADD_MATERIAL:
        case EDIT_MATERIAL:
            return { ...state, materials: {...state.materials, [`${action.payload.parentPath}/${action.payload.key}`]: action.payload} };
        case RENAME_MATERIAL:
                return { ...state, materials: {...state.materials, [`${action.payload.parentPath}/${action.payload.key}`]: action.payload} };
        case DELETE_MATERIAL:
            const deletePicker = (material, materialPath) => !materialPath.startsWith(`${action.payload.parentPath}/${action.payload.key}`);
            return { ...state, materials: _.pickBy(state.materials, deletePicker) };
        case ADD_MATERIAL_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: {...state.uploadProgress, [action.payload.key]: action.payload.progress } }
        case ADD_MATERIAL_UPLOAD_DONE:
            return { ...state, uploadProgress: _.omit(state.uploadProgress, action.payload.key)}
        default:
            return state;
    }
};