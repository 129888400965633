import React from 'react';
import ContentWrapper from './Layout/ContentWrapper';
import Image from 'react-bootstrap/Image';

const Main = () => {
    return (
        <ContentWrapper>
                <p>
                    <span className="d-block float-left p-2">
                        <Image
                            src="/images/MichaelKurushin/michael_kurushin1_lq.png"
                            alt="Курушин Михаил Иванович"
                            style={{ width: '324px', height: '490px' }}
                            rounded
                        />
                    </span>
                    <span className="p-2">
                        <b>Курушин Михаил Иванович</b>, кандидат технических наук, доцент кафедры основ конструирования машин, Самарский государственный аэрокосмический университет имени академика С. П. Королева (национальный исследовательский университет).
                    </span>
                </p>
                <p>
                        Область научных интересов: динамика механизмов с типовыми деталями машин с учетом влияния контактной гидродинамики (зубчатые колеса, подшипники качения, шлицевые соединени, валы, резьбовые, штифтовые и шпоночные соединения)
                </p>
        </ContentWrapper>
    );
}

export default Main;