import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import { getFirebase } from 'react-redux-firebase';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserMenu = () => {
    const history = useHistory();
    const {auth, profile} = useSelector(state => state.firebase);
    
    if (!isLoaded(auth) || isEmpty(auth) || !isLoaded(profile) || isEmpty(profile)) {
        return null;
    }

    return (
        <Navbar className="border" expand="md" style={{ backgroundColor: '#f5f5f5'}}>
            <Navbar.Toggle aria-controls="user-navbar-nav" />
            <Navbar.Collapse id="user-navbar-nav">
                <Nav className="mr-auto">
                    <Navbar.Text>
                    Здравсвуйте <span className="font-weight-bold">{auth.email}</span>
                    </Navbar.Text>
                </Nav>
                <Nav>
                    {profile.role === 'admin'  &&
                        <React.Fragment>
                            <NavLink className="nav-link" to="/manage/materials">
                                Управление Материалами
                            </NavLink>
                            <NavLink className="nav-link" to="/manage/gallery">
                                Управление Галерией
                            </NavLink>
                        </React.Fragment>
                    }
                    <Button
                        variant="outline-primary"
                        onClick={() => { getFirebase().logout(); history.push('/authorization'); }}>
                        <FontAwesomeIcon icon="door-open" size="sm" transform="up-1"/>Выход
                    </Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default UserMenu;
