import React from 'react';

const ContentWrapper = ({ children }) => {
    return (
        <div className="row p-4" >
            <div className="col">
                {children}
            </div>
        </div >
    );
}

export default ContentWrapper;