import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { closeToast } from '../actions';
import Toast from 'react-bootstrap/Toast';

const ToastMessage = ({ id, title, body, timeStr, shouldAutoHide, autoHideDelay }) => {
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();

    if (!show) {
        return null;
    }

    const onClose = () => {
        dispatch(closeToast(id));
        setShow(false);
    };

    return (
        <Toast onClose={onClose} show={show} delay={autoHideDelay} autohide={shouldAutoHide}>
            <Toast.Header>
                <strong className="mr-auto pr-3">{title}</strong>
                <small>{timeStr}</small>
            </Toast.Header>
            <Toast.Body>{body}</Toast.Body>
        </Toast>
    )
};

const Toasts = () => {
    const { toasts } = useSelector(state => ({
        toasts: state.toasts.toasts
    }));

    return ReactDOM.createPortal(
        <div style={{ position: 'fixed', top: 0, right: 0 }}>
            {Object.entries(toasts).map(([toastId, toast]) =>
                <ToastMessage key={toastId} {...toast} />
            )}
        </div>
        ,
        document.querySelector('#toasts-root')
    );
}

export default Toasts;