import React from 'react';

const Autobiography = () => {
    const imagesStyle = { width: '258px', height: '372px' };

    return (
        <div className="row p-4">
            <div className="col">
                <p>
                    <img src="/images/MichaelKurushin/michael_kurushin1_lq.png" alt="Michael Kurushin" className="p-2 float-left" style={imagesStyle} />
                    <strong>Курушин Михаил Иванович</strong> Родился 5 ноября 1924 г. в с. Араповка Вешкаймского р. Ульяновской обл. в крестьянской семье.<br />
                    Учился в школах разных районов в связи с переездами родителей.<br />
                    Начиная с 4 класса каждый год получал похвальные грамоты по успеваемости.<br />
                    Учился на стороне в г. Нижний Ломов за 24 км от родителей.<br />
                    В начале  войны в 1941 г бросил  учиться в 10 классе.<br />
                    Год работал помощником слесаря (молотобойцем в кузнице) и аппаратчиком  на спиртовом заводе Пензенской обл.<br />
                </p>
                <p>
                    24 августа 1942 г призван в Красную армию.<br />
                    Воевал до конца войны (солдат, ефрейтор, младший сержант, сержант, старший сержант) потееровцем, батальонным минометчиком и в конце войны комсоргом мотострелкового батальона в звании младшего лейтенанта.<br />
                    Ранен в ногу осколком  танкового снаряда при штурме г. Штеттин в Германии.<br />
                    Награжден боевыми наградами (орден “Отечественной войны”  2 ст., двумя медалями “За отвагу” и медалью “За боевые заслуги”).<br />
                </p>
                <p>
                    После войны служил год в Германии и год на Кавказе.<br />
                    Офицеров моего возраста домой не отпускали.<br />
                    Демобилизовался в 1947 г. Папа погиб на фронте.<br />
                    Приехал к маме в г. Куйбышев (она вышла замуж за Курушина Михаила Ивановича - старшего брата моего папа - Ивана Ивановича).<br />
                    Закончил 10–й класс (после шести летнего перерыва в учебе) и авиационный институт в г. Самаре (диплом с отличием – “красный диплом” – все оценки до единой  - “отлично” по всем экзаменам и зачетам).<br />
                </p>
                <p>
                    С 1954 г работал 16 лет инженером - расчетчиком по прочности деталей авиационных двигателей в КБ у Кузнецов Н.Д. и на моторостроительном заводе им. Фрунзе М.В.<br />
                    Руководил бригадой и отделом прочности, был ведущим конструктором по расчетам на прочность.<br />
                    В 1969 г., работая на производстве, защитил кандидатскую диссертацию по теме – “Расчет авиационных шарикоподшипников с учетом контактной гидродинамики”.<br />
                </p>
                <p>
                    С 1970 г 36 лет работал доцентом кафедры “Детали машин” в авиационном институте г Самара. Около трех лет руководил этой же кафедрой.<br />
                    Одновременно по совместительству с 1970 г и даже после ухода на пенсию (из-за болезни моей жены) работал старшим научным сотрудником в лаборатории института по хоздоговорам со многими организациями СССР.<br />
                    Опубликовал в различных изданиях более 500 печатных работ (полного учета не вел) по проблемам динамики, прочности и работоспособности деталей авиационных изделий и двигателей (много сделал отчетов по проведенным исследованиям).<br />
                    Все теоретические исследования велись в связи с дефектами в процессе создания, доводки и эксплуатации изделий.<br />
                    Особенно интересными и удачными были исследования связанные с работоспособностью шлицевых соединений, подшипников качения, зубчатых колес, резьбовых соединений и др.<br />
                    Часть из таких исследований приведены на этом сайте.<br />
                </p>
                <p>
                    <img src="/images/MichaelKurushin/nagradi.jpg" alt="Michael Kurushin" className="p-2 float-left" style={imagesStyle} />
                    В настоящее время (2015 г) исследую параметрическую динамику дифференциального редуктора совместно с компрессором турбовинтового двигателя, на котором при доводке на ресурс происходили поломки лопаток и дисков компрессора из-за чрезмерного фланкирования зубьев солнечного колеса.<br />
                    Женился в 1953 г. Жена - Александра Георгиевна Пудова, 1929 г рождения  - учительница немецкого языка.<br />
                    Мы прожили с ней 54 года. Она умерла в 2007 г. Ей исполнилось 87 лет.<br />
                    Два сына – Олег и Саша. Оба - инженеры по авиационной технике.<br />
                    Два внука – Степа и Костя. Две внучки - Оля и Мила. Правнук Дема и правнучка Света.<br />
                    Любил рисовать (карандаш, акварель, масло) и играть (балалайка, мандолина, аккардион, баян, пианино, скрипка).<br />
                    Сейчас играю на гитаре (моя эмонациональная зарядка).<br />
                    Рисовать очень хочется, но не хватает времени, в основном все его отдаю исследованиям.
                </p>
            </div>
        </div>
    );
}

export default Autobiography;