import React from 'react';
import { connect } from 'react-redux';

import { getFirebase } from 'react-redux-firebase';

import { isLoaded, isEmpty } from 'react-redux-firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import Loading from './Loading';
import ContentWrapper from './Layout/ContentWrapper';

class Authorization extends React.Component {
    componentDidMount() {
        console.log(this.props);
        //this.props.signIn('dckurushin@gmail.com', '1q2w3e4rAS');
        // if (this.props.firebase.auth.isEmpty) {
        //     firebase.login({ provider: 'google', type: 'popup' });
        // }
        // if (this.props.firebase)
        //     this.props.firebase.login({ provider: 'google', type: 'popup' })
        //const firebase = getFirebase();
        //firebase.logout()

        //const firebase = getFirestore()
        
        //const exampleTodo = 
        //firestore.collection('todos').add(exampleTodo);
    }

    onClickLogout = () => {
        const firebase = getFirebase();
        firebase.logout();
    }

    render() {
        if (!isLoaded(this.props.auth)) {
            return <Loading />;
        }

        if (isLoaded(this.props.auth) && !isEmpty(this.props.auth)) {
            return (
                <div className="row p-4">
                    <div className="col">
                        <p className="text-center">
                            <h6>Вы успешно авторезировались, воспользуйтесь  пожалуйста верхним меню пользователя для навигации!</h6>
                            <img src="/images/podshibniki_nig.png" alt="podshipniki" />
                        </p>
                    </div>
                </div>
            );
        }

        const firebase = getFirebase();

        const uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/authorization',
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID
            ],
            callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                firebase.handleRedirectResult(authResult).then(() => {
                 this.props.history.push(redirectUrl);// if you use react router to redirect
                });
                return false;
            },
            },
        };
        return (
            <ContentWrapper>
                    <h6 className="text-center">Выберите Метод Авторизации:</h6>
                    <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={firebase.auth()}
                        />
            </ContentWrapper>
        );
    }
}

/*
    <h2>Auth</h2>
    {
    !isLoaded(this.props.auth)
    ? <span>Loading...</span>
    : isEmpty(this.props.auth)
        ? <span>Not Authed</span>
        : <pre>{JSON.stringify(this.props.auth, null, 2)}</pre>
    }

    {!isEmpty(this.props.auth) && <button onClick={this.onClickLogout}>Logout</button>}
*/

const mapStateToProps = ({ firebase, firestore }) => {
    return {
        firebase,
        auth : firebase.auth,
        profile: firebase.profile
    };
};

export default connect(mapStateToProps)(Authorization);