import { 
    MODAL_ACTION_TYPE_CLOSE,
    MODAL_ACTION_TYPE_SHOW
} from '../actions/modalActionTypes';

const INITIAL_STATE = { 
    show: false,
    title: '',
    body: '',
    commitLabel: 'Ok',
    cancelLabel: 'Отмена',
    commitAction: null
};

export const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODAL_ACTION_TYPE_CLOSE:
            return { ...INITIAL_STATE };
        case MODAL_ACTION_TYPE_SHOW:
            return { ...INITIAL_STATE, show: true, ...action.payload };
        default:
            return state;   
    }
};