export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_QUERY = 'FETCH_IMAGES_QUERY';
export const FETCH_IMAGE = 'FETCH_IMAGE';
export const FETCH_IMAGE_QUERY = 'FETCH_IMAGE_QUERY';
export const FETCH_IMAGES_FINISHED = 'FETCH_IMAGES_FINISHED';
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_IMAGE_QUERY = 'ADD_IMAGE_QUERY';
export const ADD_IMAGE_UPLOAD_PROGRESS = 'ADD_IMAGE_UPLOAD_PROGRESS';
export const ADD_IMAGE_UPLOAD_DONE = 'ADD_IMAGE_UPLOAD_DONE';
export const DELETE_IMAGE_QUERY = 'DELETE_IMAGE_QUERY';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const EDIT_IMAGE_QUERY = 'EDIT_IMAGE_QUERY';
export const EDIT_IMAGE = 'EDIT_IMAGE';
