import React from 'react';
import Button from 'react-bootstrap/Button';
import Media from 'react-bootstrap/Media';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    MATERIAL_NODE_TYPE
} from '../../actions';

class MaterialsTreeNode extends React.Component {
    state = { isCollapsed : true }

    toggleMedia = () =>  {
        this.setState({ isCollapsed : !this.state.isCollapsed });
    }
    render() {
        const { material } = this.props;
        const isFile = material.nodeType ===  MATERIAL_NODE_TYPE.FILE;
        if (isFile) {
            return (
                <Media>
                        <Button
                            variant="link"
                            href={material.downloadUrl}
                            download={`${material.displayName}.${material.realExtension}`}
                            title={`${material.displayName}.${material.realExtension}, Размер ~${(material.fileSize/1024).toFixed(0)} KB`}
                            target="_blank"
                            className="text-info p-0 mr-3"
                        >
                            <FontAwesomeIcon icon="download" size="lg" transform="up-2"/>
                        </Button>
                    <Media.Body>
                        <h6>{material.displayName}</h6>
                        <p>
                        </p>
                    </Media.Body>
                </Media>
            ); 
        }

        return (
            <Media>
                <Button
                    variant="link"
                    className="text-secondary p-0 mr-3"
                    onClick={this.toggleMedia}
                    aria-controls={`children${material.displayName}`}
                    aria-expanded={this.state.isCollapsed}
                >
                    <FontAwesomeIcon icon={this.state.isCollapsed ? 'folder' : 'folder-open'} size="lg" transform="up-2"/>
                </Button>
                <Media.Body>
                    <h6
                        onClick={this.toggleMedia}
                        aria-controls={`children${material.displayName}`}
                        aria-expanded={this.state.isCollapsed}
                        className="cursor-pointer"
                    >
                        {material.displayName}
                    </h6>
                    <p>
                    </p>
                    <Collapse in={!this.state.isCollapsed}>
                        <div id={`children${material.displayName}`}>
                            {this.props.children}
                        </div>
                    </Collapse>
                </Media.Body>
            </Media>
        );
    }
};

export default MaterialsTreeNode;