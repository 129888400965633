import React from 'react';

import UploadProgressBar from './UploadProgressBar';

import { 
    showToast,
    createAndShowToast,
    createToast,
    closeToast
} from './toasts';

import firebase from 'firebase/app';

import {
    FETCH_IMAGES,
    FETCH_IMAGES_QUERY,
    FETCH_IMAGE,
    FETCH_IMAGE_QUERY,
    ADD_IMAGE,
    ADD_IMAGE_QUERY,
    ADD_IMAGE_UPLOAD_PROGRESS,
    ADD_IMAGE_UPLOAD_DONE,
    DELETE_IMAGE_QUERY,
    DELETE_IMAGE,
    EDIT_IMAGE,
    EDIT_IMAGE_QUERY,
} from './galleryActionTypes';

export const GALLERY_ROOT_PATH = 'gallery';
export const GALLERY_FS_ROOT_PATH = 'gallery';

export const fetchImages = () => async dispatch => {
    const galleryRef = firebase.database().ref(GALLERY_ROOT_PATH);
    const images = {};

    dispatch({ type: FETCH_IMAGES_QUERY });

    await galleryRef.once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            const image = childSnapshot.val();
            images[childSnapshot.key] = image;
        });
    });
    
    dispatch({
        type: FETCH_IMAGES,
        payload: images
    });
};

export const fetchImage = (key) => async dispatch => {
    const galleryRef = firebase.database().ref(`${GALLERY_ROOT_PATH}/${key}`);
    const images = {};

    dispatch({ type: FETCH_IMAGE_QUERY });

    await galleryRef.once('value', (snapshot) => {
        images[key] = {};
        snapshot.forEach((childSnapshot) => {
            const val = childSnapshot.val();
            images[key][childSnapshot.key] = val;
        });
    });
    
    dispatch({
        type: FETCH_IMAGE,
        payload: images
    });
};

export const createImage = (title, description, date, file) => {
    return {
        title, 
        description,
        date,
        file
    }
}

export const addImage = (image) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    // to generate the key

    const imageRef = firebase.database().ref(GALLERY_ROOT_PATH).push();

    const fileName = image.file.name;
    image.realFileName = fileName;
    image.realExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
    let uploadTask;
    try {
        uploadTask = getFirebase().storage().ref().child(
            `${GALLERY_ROOT_PATH}/${imageRef.key}.${image.realExtension}`
            ).put(
            image.file
        );
    } catch (error) {
        console.error(error);
        return;
    }

    const progressRef = React.createRef();
    const toast = createToast("Картинка Загружается", <><h6 className="text-center">{image.title}.{image.realExtension}</h6><UploadProgressBar ref={progressRef}/></>);
    dispatch(showToast(toast));

    uploadTask.on('state_changed', function(snapshot) {
        const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        if (progressRef.current) {
            progressRef.current.UpdateProgress(progress);
        }

        dispatch({
            type: ADD_IMAGE_UPLOAD_PROGRESS,
            payload: {
                key: imageRef.key,
                progress
            }
        })

        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                break;
            case firebase.storage.TaskState.RUNNING:
                break;
            default: break;
        }
    });

    await uploadTask;

    dispatch(closeToast(toast.id));

    dispatch({
        type: ADD_IMAGE_UPLOAD_DONE,
        payload: {
            key: imageRef.key
        }
    });
    
    image.downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
    image.fileSize = uploadTask.snapshot.totalBytes;

    dispatch({ type: ADD_IMAGE_QUERY });

    await imageRef.set(image);
    
    dispatch({
        type: ADD_IMAGE,
        payload: { key: imageRef.key, ...image }
    });

    dispatch(createAndShowToast("Картинка Добавлена", "Картинка добавлена успешно!", true, 3000));
}

export const deleteImage = (image)  => async (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({ type: DELETE_IMAGE_QUERY });

    await getFirebase().deleteFile(`${GALLERY_ROOT_PATH}/${image.key}.${image.realExtension}`);

    await firebase.database().ref(`${GALLERY_ROOT_PATH}/${image.key}`).remove();

    dispatch({
        type: DELETE_IMAGE,
        payload: { ...image }
    });

    dispatch(createAndShowToast("Картинка Удалена", "Картинка удалена успешно!", true, 3000));
}

export const editImage = (image) => async dispatch => {

    dispatch({ type: EDIT_IMAGE_QUERY });

    await firebase.database().ref(`${GALLERY_ROOT_PATH}/${image.key}`).set(
        image
    );

    dispatch({
        type: EDIT_IMAGE,
        payload: { ...image }
    });
}