import { 
    CLOSE_TOAST,
    ADD_TOAST
} from './toastsActionTypes';

export const createToast = (title, body, shouldAutoHide = false, autoHideDelay = 3000) => {
    const date = new Date();
    let hh = date.getHours();
    let mm = date.getMinutes();
    if (mm < 10) {
        mm  = '0' + mm;
    }
    if (hh < 10) {
        hh  = '0' + hh;
    }
    return { id: date.getTime(), title, body, timeStr: `${hh}:${mm}`, shouldAutoHide, autoHideDelay };
};

export const createAndShowToast = (title, body, shouldAutoHide = false, autoHideDelay = 3000) => {
    return {
        type: ADD_TOAST,
        payload: createToast(title, body, shouldAutoHide, autoHideDelay)
    };
};

export const showToast = (toast) => {
    return {
        type: ADD_TOAST,
        payload: toast
    };
};

export const closeToast = (id) => {
    return {
        type: CLOSE_TOAST,
        payload: id
    };
};