import React from 'react';
import ReactDOM from 'react-dom';

import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../actions/modal';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalDialog = () => {
    const { 
        show,
        title,
        body,
        commitLabel,
        cancelLabel,
        commitAction,
        size = "lg",
        showFooter = true
    } = useSelector(({modal}) => ({...modal}));

    const dispatch = useDispatch();
  
    const tryCommit = () => {
        // check if custom action provided
        if (commitAction) {// if provided
            if (!commitAction()) {
                return;//prevent modal from closing
            }
        }
        dispatchClose();
    }

    const dispatchClose = () => dispatch(closeModal());
  
    return ReactDOM.createPortal(
            <Modal
                show={show}
                onHide={dispatchClose}
                size={size}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { body }
                </Modal.Body>
                {showFooter &&
                    <Modal.Footer>
                        <Button variant="primary" onClick={tryCommit}>{commitLabel}</Button>
                        <Button variant="secondary" onClick={dispatchClose}>{cancelLabel}</Button>
                    </Modal.Footer>
                }
            </Modal>
        ,
        document.querySelector('#modal-root')
    );
}

export default ModalDialog;