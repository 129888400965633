import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
    return (
        <Navbar expand="md" variant="light" bg="light">
                <Link className="navbar-brand" to="/">
                    <img src="/images/podshibniki.png" width="30" height="30" className="d-inline-block align-top" alt=""/>
                    <strong>Динамика 63</strong>
                </Link>
                <Navbar.Toggle aria-controls="header-navbar-nav" />
                <Navbar.Collapse id="header-navbar-nav">
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item">
                        <NavLink className="nav-link" exact to="/">Главная</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/autobiography">Автобиография</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/materials">Материалы</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/gallery">Галерия</NavLink>
                    </li>
                </ul>
                </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
